import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.store('radioBar', {
    playing: false,
    clickPlayHint: false,
    clickPlayHintTimeout: -1,
    player: null,
    volume: 0.5,

    init() {
        if (import.meta.env.PROD) {
            this.tryStartPlayer();
        }
    },

    initializePlayer() {
        this.player = new Howl({
            src: ['https://s2.ssl-stream.com/listen/habbofeeling/radio.mp3'],
            html5: true,
            volume: this.volume
        });
    },

    tryStartPlayer() {
        this.playing = true;
        this.clickPlayHint = false;
        clearTimeout(this.clickPlayHintTimeout);

        this.initializePlayer();

        this.player.once('playerror', () => {
            this.stopPlayer();
            this.displayPlayHint();
        });

        this.player.once('play', () => {
            this.player.fade(0, this.volume, 1000);
        });

        this.player.volume(0);
        this.player.play();
    },

    stopPlayer() {
        this.playing = false;
        this.player.stop();
        this.player.unload();
    },

    changeVolume(volume) {
        this.volume = volume;
        this.player.volume(volume);
    },

    displayPlayHint() {
        this.clickPlayHint = true;
        this.clickPlayHintTimeout = setTimeout(() => {
            this.clickPlayHint = false;
        }, 30000);
    }
});