import Toastify from 'toastify-js';
import { Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';

import { Tooltip } from 'bootstrap';
import $ from 'jquery';
import '../../vendor/rappasoft/laravel-livewire-tables/resources/imports/laravel-livewire-tables-all.js';

function tooltips() {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });
}

function spoilers() {
    $(function () {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });

        $('div.spoiler-title').click(function () {
            $(this).children().first().toggleClass('show-icon').toggleClass('hide-icon');
            $(this).parent().children().last().toggle();
        });
    });
}

function onlineUsers() {
    Alpine.store('onlineUsers', {
        init() {
            this.userLoggedIn();
        },

        userLoggedIn() {
            const nameSort = (a, b) => a.name.localeCompare(b.name);
            Echo.leave('online');
            Echo.join('online')
                .here((users) => (this.list = users).sort(nameSort))
                .joining((user) => (this.list = [...this.list, user].sort(nameSort)))
                .leaving((user) => (this.list = this.list.filter((u) => u.id !== user.id)));
        },

        list: [],
    });
}

window.toast = function (type, message, destination = null) {
    Toastify({
        text: message,
        duration: 7000,
        close: true,
        gravity: 'bottom',
        position: 'right',
        stopOnFocus: true,
        ...(destination && {
            onClick: function () {
                Livewire.navigate(destination);
            },
        }),
        style: {
            background: {
                primary: '#cce5ff',
                secondary: '#e2e3e5',
                success: '#d4edda',
                danger: '#f8d7da',
                warning: '#fff3cd',
                info: '#d1ecf1',
                light: '#fefefe',
                dark: '#343a40',
                white: '#d6d8d9',
            }[type],
            color: {
                primary: '#004085',
                secondary: '#383d41',
                success: '#155724',
                danger: '#721c24',
                warning: '#856404',
                info: '#0c5460',
                light: '#818182',
                dark: '#ffffff',
                white: '#1b1e21',
            }[type],
        },
    }).showToast();
};

function init() {
    tooltips();
    spoilers();
    onlineUsers();
}

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('livewire:navigated', init);

document.addEventListener(
    'livewire:init',
    function () {
        Livewire.on('closeRadioLineModal', () => {
            $('#shoutLine').modal('hide');
            $('#requestLine').modal('hide');
            $('#competitonLine').modal('hide');
        });
    },
    { once: true },
);
